import { mapActions } from 'vuex'

export default {
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    itemSync: {},
    model: {},
    show: false,
    showSync: false,
    showTeacherSharing: false,
    showForm: false,
    showFormGenerate: false,
    modelGenerate: {},
    text: 'Carregando dados...',
    loadingProgress: false,
    loadingRefresh: false,
    textDelete: '',
    deleting: false,
    synchronizing: false,
    
    headers: [
        { text: 'Descrição', value: 'description', sortable: false},
        { text: 'Turno', value: 'shift.name', sortable: false},
        { text: 'Campus', value: 'campuses', sortable: false},
        { text: 'Status', value: 'status', align: 'center', sortable: false},
        { text: 'Otimização', value: 'optimization_rate', align: 'center', sortable: false},
        { text: 'Data / Horário', value: 'updated_at', type: 'dateTime',  align: 'center', sortable: false},
        { text: '', value: 'actions', align: 'center', sortable: false},
    ]
    }),

    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item " + item_new.description + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
      showFormGenerate(show){
        if(!show){
            this.loader()
        }
      }
    },
    methods: {
      ...mapActions('dynamicGeneration', ['ActionFindDynamicTimes', 'ActionDeleteDynamicTime', 'ActionDynamicTimeSync']),
        
    getData(showProgress = true, loadingRefresh = false){
      
        this.error = false;
        this.message = ''; 
        this.loadingProgress = showProgress;
        this.loadingRefresh = loadingRefresh;
        this.showFormGenerate = false;
        var params = this.params;
        params.with = 'status,shift,campuses,categories,days';

        if(showProgress){
            this.showForm = false;
        }
        
        this.ActionFindDynamicTimes(params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
                this.loadingRefresh = false;
            })
    },

    showSyncItem(item){
        this.itemSync = item;
        this.showSync = true;
    },

    getTextSync(){
        
        if(this.itemSync && this.itemSync.shift && this.itemSync.campuses){
            return 'Essa ação irá sobrepor todo horário do turno "' + this.itemSync.shift.name + '" da unidade "' + this.itemSync.campuses.map(o => o['abbreviation']).join(',') + '"!';
        }else{
            return "";
        }
        
    },


    sync(){

        this.synchronizing = true;
        
        this.ActionDynamicTimeSync({ id: this.itemSync.id })
            .then((res) => {
                this.getData();
            })
            .finally(() => {
                this.showSync = false;
                this.synchronizing = false;
            });

    },

    // viewResults(item){

    //     let route = this.$router.resolve({ path: '/dynamic-time-results', query: { id: item.id } });
    //     window.open(route.href, '_blank');

    // },


    deleteItem()
    {
        this.deleting = true;
        this.message = '';

        this.ActionDeleteDynamicTime({ id: this.itemDelete.id })
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
            });
    },

    enableIcon(item){
      return item.status.id == 1 || item.status.id == 5 || item.status.id == 6;
    },

    showGenerate(item){
        this.showFormGenerate = true;
        this.modelGenerate = item;
    },

    getColor (status) {
        
        switch (status.id) {
            case 1: return 'blue lighten-1';
            case 2: return 'amber';
            case 3: return 'blue';
            case 4: return 'light green';
            case 5: return 'light purple';
            case 6: return 'light red';
            default:  return '';
        }
      },

    loader(){
        
        // setTimeout(() => {  
            
        //     if(this.$route.name != 'dynamicGeneration'){
        //         return;
        //     }
            
        //     if(!this.showFormGenerate){
        //         this.getData(false);
        //         this.loader();
        //     }
        // }, 30000);
    },

    getCampuses(teacher){

        if(teacher.campuses && teacher.campuses.length  > 0){
            
            let total = teacher.campuses.length;
            let limit = 5;
            
            if(total < limit){
                return teacher.campuses.map(o => o['abbreviation']).join(', ');
            }else{
                let items = [];

                for (let index = 0; index < limit; index++) {
                    items.push(teacher.campuses[index]);
                    
                }

                return items.map(o => o['abbreviation']).join(', ') + ' (+' + (total - limit) + ')';
            }

            
        }else{
            return "";
        }

    },
  },
  created(){
      this.loader()
  }

}
